
@media only screen and (min-width: 320px) and (max-width: 480px){
    .chef-logo-mob{
        width: 30%!important;
    }

    .image-meta-card .ant-card-body{
        padding: 12px 9px;
    }

    .profile-update-form-modal{
        top: 150px;
    }

    .star-chef-container::-webkit-scrollbar,
    .best-selling-dish-container::-webkit-scrollbar {
        display: none;
    }

    .food-category-container::-webkit-scrollbar,
    .search-filters::-webkit-scrollbar {
        display: none;
    }

    .chef-review-modal{
        top: 250px!important;
    }
    
}

@media only screen and (min-width: 481px){
    .chef-logo-mob{
        width: 25%!important;
    }
}

@media only screen and (min-width: 640px){
    .chef-logo-desktop-box .ant-image{
        width: 50%;   
    }
    .chef-logo-desktop{
        width: 70%!important;
    }
}

@media only screen and (min-width: 768px){
    .chef-logo-desktop-box .ant-image{
        width: 50%;   
    }
    .chef-logo-desktop{
        width: 50%!important;
    }

    .auth-form-modal{
        width: 50%!important;
    }

    .auth-form-modal .ant-modal-content{
        padding: 20px 40px;
    }
}

/* @media only screen and (min-width: 980px){

} */

@media only screen and (min-width: 890px){
}


@media only screen and (min-width: 1025px){
    .chef-logo-desktop-box .ant-image{
        width: 50%;   
    }
    .chef-logo-desktop{
        width: 40%!important;
    }

    .auth-form-modal{
        width: 42%!important;
    }
}

@media only screen and (min-width: 1150px){
}

@media only screen and (min-width: 1280px){
    .chef-logo-desktop-box .ant-image{
        width: 55%;   
    }
    .chef-logo-desktop{
        width: 30%!important;
    }
    
    .auth-form-modal{
        width: 35%!important;
    }
    .chat-signin{
        margin-top:10%;
    }
}

@media only screen and (min-width: 1380px){
}
