@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primaryColor: #445626;
  --secondaryColor: #96B85D;
  --textColor: #171A21;
  --paragraphColor: #b5b1b1;
  --activeButton: #7C9D45;
  --deleteColor: #860c0c;
}

@font-face {
  font-family: 'OnestRegular';
  src: local('OnestRegular'), url(./assets/fonts/Onest-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OnestSemibold';
  src: local('OnestSemibold'), url(./assets/fonts/Onest-SemiBold.ttf) format('truetype');
}


body {
  margin: 0;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.public-chef-dish-accordian[data-dishcount='0']{
  display:none;
}
