/* App wide css */

/* Hide scrollbar for Chrome, Safari and Opera */
.main-content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.header-banner-section{
    background-image: url('../imgs//home/home-banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.restaurant-update-details-form{
    top: 0px;
}

.restaurant-update-details-form .ant-modal-content{
    padding: 20px 15px;
}

.profile-update-form-modal .ant-modal-content .ant-modal-header,
.auth-form-modal .ant-modal-content .ant-modal-header{
    padding: 10px 0;
}

.profile-update-form-modal .ant-modal-content .ant-modal-title,
.auth-form-modal .ant-modal-content .ant-modal-title,
.restaurant-update-details-form .ant-modal-content .ant-modal-title{
    font-size: 24px;
}

.auth-form__login_phone-input input,
.auth-form__signup_email-input,
.auth-form__signup_name-input {
    padding: 10px;
}

.banner-mobile-user-icon{
    width: 32px;
    height: 32px;
    color: #fff;
}

/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-inline-end: 8px;
}
  
.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
    float: right;
}

.update-profile-form-input,
.auth-otp-input,
.onboard-form-input{
    padding: 12px 10px;
}

.onboard-form-input.autocomplete{
    width:100%;
    border:1px solid #d9d9d9;
    border-radius:6px;
    margin-bottom:10px;
}

.onboard-form-select{
    height:48px;
}

.ant-collapse-header-text{
    font-size: 16px;
    font-weight: 600;
}

.ant-select-selector,
.ant-picker-range{
    width: 100%!important;
    padding: 12px 10px!important;
}

.ant-steps-item-active .ant-steps-item-content .ant-steps-item-title{
    color: var(--textColor)!important;
}

.MuiStepLabel-iconContainer .Mui-active,
.Mui-completed{
    color: var(--primaryColor)!important;
}

.ant-picker-ok button{
    background-color: var(--primaryColor)!important;
    color: #fff!important;
    height: 28px!important;
}


.image-drag-box .ant-upload-drag{
    background: #fff!important;
    border: transparent!important;
}

.ant-upload .ant-upload-btn{
    margin: 0 auto;
    border: 1px solid #d9d9d9;
    padding: 20px 16px!important;
}

.MuiBottomNavigation-root > .MuiBottomNavigationAction-root > .Mui-selected{
    color: var(--primaryColor);
}

.MuiBottomNavigation-root > .Mui-selected > svg{
    color: var(--primaryColor);
}

.top-search-tablist .Mui-selected{
    background-color: transparent!important;
}

.top-search-tablist .Mui-selected::after{
    color: var(--secondaryColor);
}

.user-search-input input{
    background-color: rgb(249 250 251);
}


/* .ant-collapse-header .ant-collapse-header-text{
    font-family: 'OnestRegular';
} */

.restaurant_contact,
.restaurant_opening_hour_timing{
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.ant-radio-button-checked{
    background-color: var(--activeButton);
}

.service-type-checkbox-group{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}


.MuiBottomNavigation-root > .MuiBottomNavigationAction-root > .Mui-selected{
    color: var(--primaryColor);
}

.MuiBottomNavigation-root > .Mui-selected > svg{
    color: var(--primaryColor);
}

.food-item-details-reviews > .ant-collapse-item > .ant-collapse-header{
    padding: 12px 0;
}

.food-item-details-reviews > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
    padding: 0;
}

.quantity_price__additional_input_container > div:first-child{
    width: 96%;
}

.quantity_price__additional_input_container > div:last-child{
    width: 4%;
}

.quantity_price__add_field_btn{
    height: 37px;
    padding: 5px 23px;
}

.quantity_price__add_field_btn:hover,
.quantity_price__add_field_btn:active{
    color: var(--primaryColor)!important;
    border-color: var(--primaryColor)!important;
}

#create_post_form .ant-form-item:not(:last-child){
    padding: 0 20px;
}

.post_picture_input{
    padding: 20px!important;
}

.post_picture_input .ant-form-item-row .ant-form-item-label label,
.post_caption_input .ant-form-item-row .ant-form-item-label label{
    font-size: 16px;
    font-weight: 500;
    font-family: 'OnestRegular';
}

.profile_notification_switch .ant-switch,
.profile_availability_switch .ant-switch{
    background-color: #adadad;
}

.profile_notification_switch .ant-switch-checked,
.profile_availability_switch .ant-switch-checked{
    background: var(--primaryColor);
}

.ant-switch-checked:hover:not(.ant-switch-disabled){
    background: var(--primaryColor)!important;
}

.logout_btn:hover,
.logout_btn:active{
    background-color: #fff!important;
}

.deactivate_btn:hover,
.deactivate_btn:active{
    background-color: #860c0c!important;
}

.profile_edit_card{
    margin: 10px 0;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 0.07)!important;
    cursor: pointer;
}

.profile_edit_card .ant-card-body{
    padding: 15px;
}

.post-delete-modal .ant-modal-content{
    padding: 50px 24px;
}

.post-delete-modal .ant-modal-confirm-paragraph{
    align-items: center;
}

.post-delete-modal .ant-modal-confirm-paragraph .ant-modal-confirm-content{
    text-align: center;
}

.post-delete-modal .anticon-exclamation-circle{
    display: none;
}

.post-delete-modal .ant-modal-confirm-btns{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    row-gap: 10px;
}


.post-delete-modal .ant-modal-confirm-btns button{
    width: 75%;
    margin-inline-start: 0!important;
    height: 50px;
}

.post-delete-modal .ant-modal-confirm-btns .ant-btn-dangerous{
    border-color: #860c0c!important;
    background-color: #860c0c!important;
    color: #fff!important;
    font-weight: 600;
}

.public-profile-btn button{
    height: 42px!important;
    font-family: 'OnestRegular';
    font-size: 14px!important;
}

.public-view-tab-container .MuiTabList-root .Mui-selected{
    background-color: transparent!important;
    color: var(--activeButton);
}

.user-search-input .ant-input-group .ant-input-affix-wrapper{
    height: 45px;
}

.user-search-input .ant-input-group .ant-input-group-addon{
    display: none;
}

.user-profile-image-badge-container .MuiBadge-badge{
    padding: 4px;
    background-color: #dfdede;
}

.user-login-container{
    position: relative;
    background: linear-gradient( rgba(64, 63, 65, 0.101), #445626e0), url('../imgs/login_banner.png') left top no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    /* height: 50vh; */
    height: 60vh;
}

.chef-review-modal{
    top: 150px;
}

.chef-logo-desktop-box .ant-image{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.chef-share-btn{
    width: fit-content;
    height: fit-content;
    padding: 2px 10px;
    border-radius: 12px;
    font-size: 12px;
    background-color: var(--textColor);
    color: #fff;
}

.chef-share-btn:not(:disabled):not(.ant-btn-disabled):hover{
    background-color: var(--textColor);
    color: #fff;
}

.star-chef-container::-webkit-scrollbar,
.best-selling-dish-container::-webkit-scrollbar {
    display: none;
}


/* .post-delete-modal .ant-modal-confirm-btns .ant-btn-dangerous span::before{
    content: '\1F5D1'
} */


/* Collapse icon palce change */

/* .ant-collapse-header{
    flex-direction: row-reverse!important;
} */

/* .ant-collapse-expand-icon .ant-collapse-arrow svg{
    transform: rotate(-90deg)!important;
} */

.chat-signin{
    text-align: center;
    margin-top: 50%;
}
.pubnubprovider{
    height: 80%;
}
.opendrawer{
    bottom:150px;
}

.pn-msg-input{
    width: 100%;
}

.pn-msg {
    padding: 5px 16px;
}

.pn-msg-list{
    background: #F1F3F7;
}

.pn-msg-input{
    background: #F1F3F7;
}

.chat-msg-box-top{
    position: sticky;
    z-index: 1;
    background-color: #fff;
    top: 0;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 0.1);
    border-top: #fff;
}

.chat-msg-box-middle{
/*    margin-top: 90px;*/
/*    margin-bottom: 85px;*/
min-height:100%;
}

.chat-msg-box-bottom{
    position: fixed;
    background-color: #fff;
    bottom: 0;
    z-index: 2;
    padding-top:15px;
}

.ant-spin-dot-item{
    background-color: #6e8b3d!important;
}

.ant-btn-default:hover {
    border-color: #d9d9d9!important;
    color: #000!important;
}

.pn-msg{
    background-color: #fff;
}

.pn-msg:hover{
    background-color: #fff;
}

.liked-post:hover{
    background-color: #fff!important;
    color: #e6395f!important
}

.MuiAccordionSummary-root{
    min-height: 45px!important;
}

.MuiAccordionSummary-root > .MuiAccordionSummary-content{
    margin: 10px 0!important;
}

.feed-create-btn.Mui-disabled{
    color: #ffffff!important;
    background-color: #adadad!important;
}

.post-delete-modal .ant-modal-confirm-body{
    justify-content: center;
}

.ant-select-multiple .ant-select-selector{
    height: auto!important;
}
